import { get, post } from "@/utils/axios";

export function scaLogin(testLoginCode) {
  return post("/scalogin/ScaLogin", JSON.stringify(testLoginCode));
}

export function scaAutoLogin(autoLoginCode) {
  return post("/scalogin/ScaAutoLogin", JSON.stringify(autoLoginCode));
}

export function fetchIncompleteTestList({ userGuid, groupGuid, personGuid }) {
  return post("/scagroup/GetIncompleteTest", {
    customerGuid: userGuid,
    testGroupGuid: groupGuid,
    personGuid
  });
}

export function fetchLbDetailsFromGuid(lbGuid) {
  return get(`/scalb/LbScaInfoFromGuid/${lbGuid}`);
}

export async function fetchAllQuesWithAnsFromLbGuid({
  userGuid,
  groupGuid,
  lbGuid,
  personGuid
}) {
  let response = await post("/scalb/AllQuesListWithAnsFromLbGuid", {
    customerGuid: userGuid,
    testGroupGuid: groupGuid,
    lbGuid,
    testPersonGuid: personGuid
  });
  return {
    caseGuid: response.caseGuid,
    quesAnsList: response.quesAnsList,
    timeCost: response.timeCost,
    optionZeroKeyNum: response.optionZeroKeyNum,
    nextAnsStatus: mapToAnswerStatus(response.nextAnswerStatus)
  };
}

export async function searchPersonInfo(userGuid, { deptGuid, testeeNum }) {
  let response = await post(`/scaperson/SearchTestPersonInfo/${userGuid}`, {
    deptGuid,
    testeeNum
  });
  return response.itemList;
}

export function getPersonInfoFromGuid(personGuid) {
  return get("/scaperson/GetTestPersonInternal", { personGuid });
}

export async function fetchIsShowScaReport(groupGuid) {
  let response = await get("/scagroup/IsShowScaReport", { groupGuid });
  return response.value;
}

export async function fetchIsContinuousTest(groupGuid) {
  let response = await get("/scagroup/IsContinuousTest", { groupGuid });
  return response.value;
}

export async function saveOneAnswerAndGetNext({
  caseGuid,
  index,
  answer,
  timeCost
}) {
  let response = await post("/scalb/SaveOneTestAnswerAndGetNext", {
    caseGuid,
    index,
    answer,
    timeCost
  });
  return mapToAnswerStatus(response);
}

export async function fetchNextAnswerStatus(caseGuid, quesIndex) {
  let response = await post("/scalb/GetNextAnswerStatus", {
    caseGuid,
    quesIndex
  });
  return mapToAnswerStatus(response);
}

export async function fetchPrevAnswerStatus(caseGuid, quesIndex) {
  let response = await post("/scalb/GetPrevAnswerStatus", {
    caseGuid,
    quesIndex
  });
  return mapToAnswerStatus(response);
}

export async function startOneTest({ caseGuid }) {
  let response = await post("/scalb/StartOneTest", {
    guid: caseGuid
  });
  return response.guid;
}

export async function completeOneTest({ caseGuid, timeCost }) {
  let response = await post("/scalb/CompleteOneTest", {
    caseGuid,
    timeCost
  });
  return response.guid;
}

export async function saveOneTest({ caseGuid, timeCost }) {
  let response = await post("/scalb/SaveOneTest", {
    caseGuid,
    timeCost
  });
  return response.guid;
}

export async function terminateOneTest({ caseGuid }) {
  let response = await post("/scalb/TerminateOneTest", {
    guid: caseGuid
  });
  return response.guid;
}

function mapToAnswerStatus(response) {
  return {
    nextTitleIndex: response.nextTitleIdx,
    nextQuesIndex: response.nextIdx,
    totalQuesCount: response.quesCnt,
    answeredQuesCount: response.ansQuesCnt,
    allowPrev: response.allowPrev,
    allowNext: response.allowNext,
    isShowProgress: response.showProg,
    timeLimitSec: response.timeLimSec && Number(response.timeLimSec),
    defaultAns: response.defAns
  };
}
