<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-form @submit.prevent="doTestLogin(testLoginCode)">
      <v-card max-width="600px" class="mx-auto my-12 px-10 py-6" elevation="6">
        <v-card-title class="px-4 mt-6 mb-8">
          <div>心理测量中心 - 登录</div>
          <v-spacer></v-spacer>
          <v-btn icon large @click="backToAdminLogin">
            <v-icon>mdi-account-cog</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-8">
          <div>
            <v-text-field
              outlined
              dense
              label="输入测量码"
              v-model="testLoginCode"
              :error="!!loginErrorMsg"
              :error-messages="loginErrorMsg"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions class="px-8">
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            depressed
            color="primary px-8"
            :disabled="isBtnLoading"
            :loading="isBtnLoading"
            >登录</v-btn
          >
        </v-card-actions>
        <v-divider class="mt-10"></v-divider>
        <v-card-text>
          <div>{{ loginPanelTitle }}</div>
          <div>{{ copyright }}</div>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import { fetchSingletonCustInfo } from "@/api/user";
import { scaLogin, scaAutoLogin } from "@/api/sca";

export default {
  components: {
    AppLoadingSpinner
  },

  props: {
    scacode: {
      type: String
    }
  },

  data() {
    return {
      isLoading: true,
      testLoginCode: "",
      isLoginFailed: false,
      userCustInfo: {
        displayName: "",
        description: "",
        website: "",
        landingTitle: "",
        isSingleton: true
      },
      loginErrorMsg: "",
      isBtnLoading: false
    };
  },

  watch: {
    testLoginCode() {
      this.showErrorMsg(null);
    }
  },

  computed: {
    loginPanelTitle() {
      if (this.userCustInfo.landingTitle) {
        return this.userCustInfo.landingTitle;
      } else if (this.userCustInfo.displayName) {
        return this.userCustInfo.displayName;
      } else if (!this.userCustInfo.isSingleton) {
        // 多用户的情况下才叫这个名字
        return "心理健康测评系统 3.0";
      }
      return "";
    },
    copyright() {
      return `Copyright ${new Date().getFullYear()}`;
    }
  },

  methods: {
    showErrorMsg(msg) {
      this.loginErrorMsg = msg;
    },
    backToAdminLogin() {
      this.$router.push({ name: "login" });
    },
    async loadCustInfoIfSingleton() {
      try {
        this.isLoading = true;
        this.userCustInfo = await fetchSingletonCustInfo();
      } catch (err) {
        this.loginMessage = err.message;
      }
      this.isLoading = false;
    },
    async doTestLogin(loginCode, loginMode = "") {
      if (loginCode && loginCode.length > 0) {
        try {
          this.isBtnLoading = true;
          // 首先清空上次的缓存
          this.$store.dispatch("sca/clearScaState");
          let loginUserState =
            loginMode === "auto"
              ? await scaAutoLogin(loginCode)
              : await scaLogin(loginCode);
          if (loginUserState) {
            let unsortedLbList = loginUserState.testLbList;
            this.$store.dispatch("sca/initScaState", {
              userGuid: loginUserState.customerGuid,
              userDispName: loginUserState.customerDispName,
              groupGuid: loginUserState.testGroupGuid,
              testLbList: unsortedLbList.sort(
                (lb1, lb2) => lb1.order - lb2.order
              )
            });
            this.$router.push({ name: "scainfoinput" });
          } else {
            this.showErrorMsg("测量码错误");
          }
        } catch (err) {
          err && this.showErrorMsg(err.message);
        }
        this.isBtnLoading = false;
      }
    }
  },

  created() {
    // 自动登录为设定的 group
    if (this.scacode) {
      this.doTestLogin(this.scacode, "auto");
    } else {
      this.loadCustInfoIfSingleton();
    }
  }
};
</script>
