import { get, post } from "@/utils/axios.js";

export function initAndGetAllRoles() {
  return post("/customerinit/InitAndGetAllRoles");
}

export function getAllRoles() {
  return post("/role/GetAllRoles");
}

export async function initNewUser({
  loginName,
  loginPwd,
  displayName,
  roleName
}) {
  let response = await post("/customerinit/InitNewCustomer", {
    loginName,
    loginPwd,
    displayName,
    roleName
  });
  let userGuid = response.guid;
  return post("/customerinit/InitLbAndCategory", {
    customerGuid: userGuid
  });
}

export async function userAuth(username, password) {
  let response = await post("/adminauth/AdminAuthenticate", {
    userName: username,
    password: password
  });
  return {
    entityGuid: response.entityGuid,
    entity: response.entity,
    credentialGuid: response.credentialGuid,
    credentialName: response.credentialName,
    role: response.role,
    jwt: response.jwt
  };
}

export async function userSignOut() {
  let response = await post("/adminauth/AdminSignOut");
  return response.value;
}

export async function fetchUserEntityRole(userGuid) {
  let response = await get("/credential/CredentialEntityRoleFromEntityGuid", {
    t: new Date().getTime(),
    entityGuid: userGuid
  });
  return {
    entity: response.entity,
    role: response.role
  };
}

export function fetchSingletonCustInfo() {
  return get("/customer/SingletonCustomerInfo", {
    t: new Date().getTime()
  });
}

export function fetchUserCustInfo(userGuid) {
  return get("/customer/CustomerInfoFromGuid", {
    t: new Date().getTime(),
    customerGuid: userGuid
  });
}

export async function fetchPrimaryPersonInput(userGuid) {
  let response = await get(
    `/customer/PrimaryPersonInputFromCustomer/${userGuid}`
  );
  return response.value;
}

export function saveUserCustInfo(
  userGuid,
  { userName, userDesc, website, landingTitle, primaryPersonInput }
) {
  return post("/customer/SaveCustomerInfo", {
    guid: userGuid,
    displayName: userName,
    description: userDesc,
    website,
    landingTitle,
    primaryPersonInput
  });
}

export async function uploadUserPortrait(userGuid, imageFile) {
  if (!imageFile) {
    throw { message: "请点击上传图片" };
  }
  let formData = new FormData();
  formData.append("imageFile", imageFile, imageFile.name);
  let response = await post(
    `/customer/UploadCustomerPotrait/${userGuid}`,
    formData
  );
  return response.guid;
}
